<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>REPORTES</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> Reportes </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <template>
      <v-card class="overflow-hidden mt-2">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-bold" color="grey darken-3">
            REPORTE GRÁFICO
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text>
          <v-row class="mx-0">
            <v-row>
              <v-col cols="12" sm="12" lg="2" md="2">
                <span class="green--text">Desarmonía de consulta</span>

                <div class="container-filter">
                  <v-icon color="grey">mdi-map-marker-outline</v-icon>
                  <v-autocomplete
                    :items="disharmonies"
                    item-text="name"
                    item-value="id"
                    single
                    label="Tipo de desarmonias"
                    v-model="filters.disharmony_id"
                    @change="changeDisharmony"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text"
                  >Rango fecha de consulta - Inicio</span
                >

                <v-menu
                  ref="minDatePicker"
                  v-model="minDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="filters.min_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.min_date"
                      label="Rango de fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="handleClearMinDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.min_date"
                    no-title
                    scrollable
                    :max="filters.max_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="minDatePicker = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="handleSetMinDate">
                      Aplicar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text">Rango fecha de consulta - Fin</span>

                <v-menu
                  ref="maxDatePicker"
                  v-model="maxDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="filters.max_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.max_date"
                      label="Rango de fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="handleClearMaxDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.max_date"
                    no-title
                    scrollable
                    :min="filters.min_date"
                    locale="es-co"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="maxDatePicker = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="handleSetMaxDate">
                      Aplicar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                lg="2"
                md="2"
                v-if="currentTypeUser && currentTypeUser.id === 'regional'"
              >
                <span class="green--text">Zona de consulta</span>

                <div class="container-filter">
                  <v-icon color="grey">mdi-map-marker-outline</v-icon>
                  <v-autocomplete
                    :items="zones"
                    item-text="descripcion"
                    item-value="id"
                    single
                    label="Zona"
                    v-model="filters.zone_id"
                    @change="changeMunicipalities"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                lg="2"
                md="2"
                v-if="currentTypeUser && currentTypeUser.id === 'regional'"
              >
                <span class="green--text">Municipio de consulta</span>

                <div class="container-filter">
                  <v-icon color="grey">mdi-map-marker-outline</v-icon>
                  <v-autocomplete
                    :items="municipalities"
                    item-text="descripcion"
                    item-value="id"
                    single
                    label="Municipio"
                    v-model="filters.municipality_id"
                    @change="changeSelect"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                lg="3"
                md="3"
                v-if="currentTypeUser && currentTypeUser.id === 'local'"
              >
                <span class="green--text">Veredas</span>

                <div class="container-filter">
                  <v-icon color="grey">mdi-map-marker-outline</v-icon>
                  <v-autocomplete
                    :items="sidewalks"
                    item-text="descripcion"
                    item-value="id"
                    single
                    label="Vereda"
                    v-model="filters.sidewalk_id"
                    @change="changeSelect"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>

        <template
          v-if="
            currentTypeUser &&
            currentTypeUser.id &&
            currentDisharmony &&
            currentDisharmony.id >= 0 &&
            (filters.sidewalk_id || filters.municipality_id || filters.zone_id)
          "
        >
          <v-card-text>
            <v-row class="mt-2">
              <v-col md="6" lg="6" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentDisharmony.name }} TIPOLOGÍA POR UBICACIÓN
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="">
                      <cases-by-disharmony
                        :queryStats="filters"
                      ></cases-by-disharmony>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6" lg="6" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentDisharmony.name }} POR ESTADO DEL PROCESO
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="" max-height="100">
                      <cases-by-state :queryStats="filters"></cases-by-state>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col md="12" lg="12" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <div class="d-flex align-center justify-space-between">
                      <h3
                        class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                      >
                        {{ currentDisharmony.name }} POR MUNICIPIOS
                      </h3>
                      <div class="container-filter">
                        <v-icon color="grey">mdi-map-marker-outline</v-icon>
                        <v-autocomplete
                          :items="[
                            { id: 0, descripcion: 'TODOS' },
                            ...municipalities,
                          ]"
                          item-text="descripcion"
                          item-value="id"
                          single
                          label="Municipio"
                          v-model="filters.map_municipality_id"
                          @change="changeSelect"
                        ></v-autocomplete>
                      </div>
                    </div>

                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="">
                      <typology-by-municipalities-maps
                        :queryStats="filters"
                      ></typology-by-municipalities-maps>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <v-card class="overflow-hidden mt-2">
          <v-toolbar flat color="white">
            <v-toolbar-title class="font-weight-bold" color="grey darken-3">
              GENERAR REPORTE EXCEL
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="mt-2">
              <v-col md="12" lg="12" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <div class="" max-height="100">
                      <list-cases :queryStats="filters"></list-cases>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import sweetalert from "sweetalert2";

import CasesByDisharmony from "./reports/CasesByDisharmony.vue";
import CasesByState from "./reports/CasesByState.vue";
import TypologyByMunicipalitiesMaps from "./reports/TypologyByMunicipalitiesMaps.vue";
import ListCases from "./reports/ListCases.vue";

import {
  parameterization,
  municipalitiesParameterization,
} from "../../../services/graphicReports/selfJustice";
import { mapState } from "vuex";

export default {
  components: {
    CasesByDisharmony,
    CasesByState,
    TypologyByMunicipalitiesMaps,
    ListCases,
  },
  name: "graphicReports.vue",
  data() {
    return {
      currentDisharmony: {
        id: "",
        name: "",
      },
      currentTypeUser: {
        id: "",
        name: "",
      },
      zones: [],
      municipalities: [],
      sidewalks: [],
      disharmonies: [],
      filters: {
        min_date: "",
        max_date: "",
        zone_id: "",
        disharmony_id: 0,
        map_municipality_id: 0,
        municipality_id: 0,
        sidewalk_id: 0,
      },
      minDatePicker: false,
      maxDatePicker: false,
      itemsTypeUser: [
        {
          id: "regional",
          name: "Por Municipio",
        },
        {
          id: "zonal",
          name: "",
        },
        {
          id: "local",
          name: "Por Vereda",
        },
      ],
    };
  },
  methods: {
    async fillData() {
      this.showLoader();
      const { data } = await parameterization({
        type_user: this.user.type_user,
        department_id: this.user.department_id,
        territory_id: this.user.territory_id,
      });
      this.disharmonies = data.disharmonies || [];
      this.sidewalks = data.sidewalks || [];
      this.zones = data.zones || [];
      this.hideLoader();
    },
    handleClearMinDate() {
      this.filters.min_date = '';
      this.handleReloadReports(); 
    },
    handleClearMaxDate() {
      this.filters.max_date = '';
      this.handleReloadReports(); 
    },
    handleSetMinDate() {
      this.$refs.minDatePicker.save(this.filters.min_date);
      this.handleReloadReports();
    },
    handleSetMaxDate() {
      this.$refs.maxDatePicker.save(this.filters.max_date);
      this.handleReloadReports();
    },
    handleReloadReports() {
      const auxCurrentTypeUser = this.currentTypeUser;
      this.currentTypeUser = {};

      this.$nextTick(() => {
        this.currentTypeUser = auxCurrentTypeUser;
      });
    },
    changeDisharmony() {
      this.changeSelect();
      this.currentDisharmony = this.disharmonies.find(
        (item) => this.filters.disharmony_id === item.id
      );
    },
    async changeMunicipalities() {
      this.changeSelect();
      const { data } = await municipalitiesParameterization(this.filters);
      this.municipalities = data.municipalities || [];
    },
    changeSelect() {
      const auxCurrentTypeUser = this.currentTypeUser;
      this.currentTypeUser = {};
      this.$nextTick(() => {
        this.currentTypeUser = auxCurrentTypeUser;
      });
    },
  },
  async created() {
    this.currentTypeUser.id = this.user.type_user;
    this.currentTypeUser.name =
      this.itemsTypeUser.find((item) => item.id === this.user.type_user)
        ?.name || "";

    try {
      await this.fillData();
    } catch (err) {
      this.hideLoader();
      sweetalert.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style>
.container-filter {
  display: flex;
  align-items: center;
  gap: 5px;
}
.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
  z-index: 400 !important;
}
</style>
