import baseService from "../baseService";
const resource = "/rest/v1";

export function casesByState(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/cases-by-state`,
    data
  );
}

export function casesByDisharmony(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/cases-by-disharmony`,
    data
  );
}

export function typologyByMunicipalitiesMaps(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/cases-by-municipalities-maps`,
    data
  );
}

export function listCases(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/list-cases`,
    data
  );
}

export function listCasesDonwload(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/list-cases-download`,
    data,
    {
      responseType: "blob",
    }
  );
}

export function parameterization(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/parameterization`,
    data
  );
}

export function casesParameterization(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/cases-parameterization`,
    data
  );
}

export function municipalitiesParameterization(data) {
  return baseService.post(
    `${resource}/graphic_reports/justice/municipalities-parameterization`,
    data
  );
}
